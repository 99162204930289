@import '@styles/mixins.style';

.expansion-panel {
  background-color: var(--color-bg-dark);
  padding-block: var(--ra-spacing-6);
  z-index: 1;

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--ra-spacing-2);
    z-index: 10;

    @include breakpoint(md) {
      grid-template-columns: 4fr 6fr;
      gap: var(--ra-spacing-6);
    }
  }
}
